// Development by Roger That
// console.log("Current set language is: " + language);

// sass
import "../sass/main.scss";

// vendor libraries
import Chart from "chart.js/auto";
import simpleParallax from "simple-parallax-js";
import "waypoints/lib/noframework.waypoints";
// import debug waypoints
// import "waypoints/lib/shortcuts/infinite";
import Hammer from "hammerjs";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

// app functionality
import showMap from "./interaction/mapMarkers.js";
import slides from "./data/slides.js";

const version = "03 april 2024 - 18:51";
document.querySelector("#version").innerHTML = `<em>${version}</em>`;

const app = {
  init: () => {
    app.animateBackground();
    // active interactivity
    app.activeCharts = [];
    app.activeMaps = [];
    app.observer = null;
    app.dropdown = null;

    app.buildNavigation();
    app.cacheDOMElements();
    app.scrollNavigation();
    app.tileNavigation();

    app.slideActions();
    app.parallaxEffects();
    app.tippyTag();
  },
  tippyTag() {
    const tippyTags = document.querySelectorAll(".tippy-tag");
    tippyTags.forEach((tag) => {
      tippy(tag, {
        content: tag.getAttribute("data-tippy-content"),
        allowHTML: true,
        interactive: true,
        theme: "light-border",
        placement: "top",
        appendTo: document.body,
        // onShow(instance) {
        //   // set active li in dropdown
        //   const hash = window.location.hash;
        //   // log all contents of the dropdown
        //   const dropdown = instance.popper.querySelector(
        //     ".dropdown-tableofcontents"
        //   );
        //   const activeLi = dropdown.querySelector(`li[data-href="${hash}"]`);
        //   // set active class
        //   activeLi.classList.add("active");
        // },
      });
    });
  },
  animateBackground() {
    const triangles = document.querySelectorAll(".triangle");

    const randomMovement = () => {
      triangles.forEach((triangle) => {
        triangle.classList.add("active");
        const top = Math.random() * 100;
        const left = Math.random() * 100;
        const delay = Math.random() * 5;
        const rotation = Math.random() * 360; // Random rotatie in graden

        triangle.style.top = `${top}%`;
        triangle.style.left = `${left}%`;
        triangle.style.transform = `translate(-50%, -50%) rotate(${rotation}deg)`; // Random rotatie toepassen

        //   triangle.style.animationDelay = `${delay}s`;
      });
    };

    setInterval(randomMovement, 15000);
    randomMovement();
    setTimeout(randomMovement, 1000);
  },
  buildNavigation() {
    const navigationLocation = document.getElementById("scrollitems-container");

    // for (const slide of slides) {
    //   const navLink = document.createElement("a");
    //   navLink.setAttribute("href", slide.hash);
    //   navLink.innerHTML = slide.title;

    //   const navItem = document.createElement("li");
    //   navItem.setAttribute("data-href", slide.hash);
    //   navItem.appendChild(navLink);
    //   navList.appendChild(navItem);
    // }

    this.dropdown = tippy("#inhoudstafel", {
      content: `
      <ul class="dropdown-tableofcontents">
        ${slides
          .map(
            (slide) =>
              `<li data-href="${slide.hash}"><a href="${slide.hash}">${slide.title}</a></li>`
          )
          .join("")}
      </ul>`,
      allowHTML: true,
      trigger: "click",
      interactive: true,
      // set active li in dropdown
      onShow(instance) {
        const hash = window.location.hash;
        // log all contents of the dropdown
        const dropdown = instance.popper.querySelector(
          ".dropdown-tableofcontents"
        );
        const activeLi = dropdown.querySelector(`li[data-href="${hash}"]`);
        // set active class
        activeLi.classList.add("active");
      },
    });
  },
  cacheDOMElements() {
    // slide related
    this.$slides = document.querySelectorAll(".m-slide");

    // navigation related
    this.$scrollContainer = document.getElementById("scroll-container");
    this.$navBar = document.getElementById("navigation-bar");
    this.$navLinks = document.querySelectorAll(".o-navigation-bar ul li a");

    // arrow related
    this.$arrowLinks = document.querySelectorAll(".a-arrow");

    // tile related
    this.$tilesOverlay = document.getElementById("tiles");
    this.$tileLink = document.getElementById("tile");
    this.$closeTileLink = document.getElementById("close-tiles");
    this.$tileNavItems = document.querySelectorAll(".m-tile");

    this.$esgIcons = document.querySelectorAll(".m-goals");

    // fluidvids.init({
    //   selector: ["iframe", "object"], // runs querySelectorAll()
    //   players: ["www.youtube.com", "player.vimeo.com"], // players to support
    // });

    this.hammer = new Hammer(document.querySelector("body"));
  },
  animateGoals() {
    // when a goal becomes visible animate it
    for (const esgIcon of this.$esgIcons) {
      // without waypoints, but with intersection observer
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              let classNames =
                " show animate__animated animate__fadeInUp animate__delay";

              // add animation class to every img in the container
              entry.target.querySelectorAll("img").forEach((img, index) => {
                img.className += classNames + "-" + index + "s";
              });
            }
          });
        },
        {
          // set treshold to animate it later
          threshold: 1,
        }
      );

      this.observer.observe(esgIcon);
    }
  },
  scrollNavigation() {
    return false;
    var edgeSize = 100;
    var timer = null;

    document.getElementById("slides").addEventListener("mousemove", (e) => {
      // this.resetScroll();;
    });
    this.$scrollContainer.addEventListener("mousemove", handleMousemove, false);
    this.$scrollContainer.mouseIsOver = false;
    this.$scrollContainer.onmouseover = function () {
      this.mouseIsOver = true;
    };
    this.$scrollContainer.onmouseout = function () {
      this.mouseIsOver = false;
    };

    // I adjust the window scrolling in response to the given mousemove event.
    function handleMousemove(event) {
      const viewportX = event.clientX - app.$scrollContainer.offsetLeft;
      const rightMargin = app.$navBar.clientWidth;

      // Get the viewport dimensions.
      var viewportWidth = document.getElementById(
        "scrollitems-container"
      ).clientWidth;

      // Next, we need to determine if the mouse is within the "edge" of the
      // viewport, which may require scrolling the window. To do this, we need to
      // calculate the boundaries of the edge in the viewport (these coordinates
      // are relative to the viewport grid system).
      var edgeLeft = edgeSize;
      var edgeRight = viewportWidth - edgeSize;

      var isInLeftEdge = viewportX < edgeLeft;
      var isInRightEdge = viewportX > edgeRight;

      // If the mouse is not in the viewport edge, there's no need to calculate
      // anything else.
      if (!(isInLeftEdge || isInRightEdge)) {
        clearTimeout(timer);
        return;
      }

      // If we made it this far, the user's mouse is located within the edge of the
      // viewport. As such, we need to check to see if scrolling needs to be done.
      var navbarWidth = app.$scrollContainer.scrollWidth;

      // Calculate the maximum scroll offset in each direction. Since you can only
      // scroll the overflow portion of the document, the maximum represents the
      // length of the document that is NOT in the viewport.
      var maxScrollX = navbarWidth - viewportWidth;

      // As we examine the mousemove event, we want to adjust the window scroll in
      // immediate response to the event; but, we also want to continue adjusting
      // the window scroll if the user rests their mouse in the edge boundary. To
      // do this, we'll invoke the adjustment logic immediately. Then, we'll setup
      // a timer that continues to invoke the adjustment logic while the window can
      // still be scrolled in a particular direction.
      // --
      // NOTE: There are probably better ways to handle the ongoing animation
      // check. But, the point of this demo is really about the math logic, not so
      // much about the interval logic.
      (function checkForWindowScroll() {
        clearTimeout(timer);
        if (adjustWindowScroll()) {
          timer = setTimeout(checkForWindowScroll, 30);
        }
      })();

      // Adjust the window scroll based on the user's mouse position. Returns True
      // or False depending on whether or not the window scroll was changed.
      function adjustWindowScroll() {
        // Get the current scroll position of the document.
        var currentScrollX = app.$scrollContainer.scrollLeft;

        // Determine if the window can be scrolled in any particular direction.
        var canScrollLeft = currentScrollX > 0;
        var canScrollRight = currentScrollX < maxScrollX;

        // Since we can potentially scroll in two directions at the same time,
        // let's keep track of the next scroll, starting with the current scroll.
        // Each of these values can then be adjusted independently in the logic
        // below.
        var nextScrollX = currentScrollX;

        // As we examine the mouse position within the edge, we want to make the
        // incremental scroll changes more "intense" the closer that the user
        // gets the viewport edge. As such, we'll calculate the percentage that
        // the user has made it "through the edge" when calculating the delta.
        // Then, that use that percentage to back-off from the "max" step value.
        var maxStep = 10;

        // Should we scroll left?
        if (isInLeftEdge && canScrollLeft) {
          var intensity = (edgeLeft - viewportX) / edgeSize;

          nextScrollX = nextScrollX - maxStep * intensity;

          // Should we scroll right?
        } else if (isInRightEdge && canScrollRight) {
          var intensity = (viewportX - edgeRight) / edgeSize;

          nextScrollX = nextScrollX + maxStep * intensity;
        }

        // Sanitize invalid maximums. An invalid scroll offset won't break the
        // subsequent .scrollTo() call; however, it will make it harder to
        // determine if the .scrollTo() method should have been called in the
        // first place.
        nextScrollX = Math.max(0, Math.min(maxScrollX, nextScrollX));
        if (nextScrollX !== currentScrollX) {
          if (app.$scrollContainer.mouseIsOver) {
            app.$scrollContainer.scrollTo(nextScrollX, 0);
          } else {
            // app.$scrollContainer.stop();
          }

          return true;
        } else {
          return false;
        }
      }
    }
  },
  resetScroll() {
    const li = document.querySelector(`li.active`);
    if (!li) return false;
    const scrto = li.offsetLeft - 300;

    app.$scrollContainer.scrollTo(scrto, 40);
  },
  tileNavigation() {
    this.$tileLink.addEventListener("click", (e) => {
      e.preventDefault();
      this.$tilesOverlay.classList.remove("hidden");
      document.body.classList.add("overlay-active");
    });
    this.$closeTileLink.addEventListener("click", (e) => {
      e.preventDefault();
      this.$tilesOverlay.classList.add("hidden");
      document.body.classList.remove("overlay-active");
    });

    window.onresize = () => {
      this.$tilesOverlay.style.minHeight = document.height;
    };
  },
  slideActions() {
    // set initial hash if no hash is active
    window.location.hash == "" ? (window.location.hash = "cover") : "";

    // show initial slide
    toggleSlides(window.location.hash);
    toggleLinks(window.location.hash);

    // if click on logo, go to cover
    const logo = document.querySelector(".a-logo");
    logo.addEventListener("click", (event) => {
      event.preventDefault();
      window.location.hash = "#cover";
      toggleSlides("#cover");
      toggleLinks("#cover");
    });

    // arrow actions
    for (const arrowLink of this.$arrowLinks) {
      arrowLink.addEventListener("click", (event) => {
        event.preventDefault();
        const hrefLocation = arrowLink.getAttribute("href");
        const hashLocation = window.location.hash;
        console.log(hrefLocation, hashLocation);
        toggleSlides(hrefLocation);
        toggleLinks(hrefLocation);
        window.location.hash = hrefLocation;
        if (hashLocation != hrefLocation) {
        }
      });
    }

    // click in dropdown
    this.dropdown[0].popper.addEventListener("click", (event) => {
      if (event.target.tagName !== "A") return false;
      const hrefLocation = event.target.getAttribute("href");
      const hashLocation = window.location.hash;
      if (hashLocation != hrefLocation) {
        toggleSlides(hrefLocation);
        toggleLinks(hrefLocation);
      }
      // hide dropdown
      this.dropdown[0].hide();
    });

    // navigation actions
    // for (const navLink of this.$navLinks) {
    //   navLink.addEventListener("click", (event) => {
    //     if (event.currentTarget.getAttribute("id") === "tile") return false;
    //     const hrefLocation = event.target.getAttribute("href");
    //     const hashLocation = window.location.hash;
    //     if (hashLocation != hrefLocation) {
    //       toggleSlides(hrefLocation);
    //       toggleLinks(hrefLocation);
    //     }
    //   });
    // }

    // swipe actions
    app.hammer.on("swipe", (event) => {
      // disable if map is active (m-kantoren has class active)
      if (document.querySelector(".m-kantoren.active")) return false;

      let hrefLocation;
      if (event.direction == 2) {
        hrefLocation = document.querySelector("#next").getAttribute("href");
      } else if (event.direction == 4) {
        hrefLocation = document.querySelector("#previous").getAttribute("href");
      } else {
        return;
      }

      const hashLocation = window.location.hash;
      if (hashLocation != hrefLocation) {
        window.location.hash = hrefLocation;
        toggleSlides(hrefLocation);
        toggleLinks(hrefLocation);
        this.resetScroll();
      }
    });

    for (const slideLink of this.$tileNavItems) {
      slideLink.addEventListener("click", (event) => {
        const hrefLocation = event.currentTarget.getAttribute("data-href");
        const hashLocation = window.location.hash;

        if (hashLocation != hrefLocation) {
          window.location.hash = hrefLocation;
          toggleSlides(hrefLocation);
          toggleLinks(hrefLocation);
          this.resetScroll();
        }
        app.$tilesOverlay.classList.add("hidden");
        document.body.classList.remove("overlay-active");
      });
    }

    function toggleSlides(id) {
      // does this slide exist?
      if (!document.querySelector(`div${id}`)) return false;

      // show and hide slides
      for (const slide of app.$slides) {
        if (slide.classList.contains("active")) {
          slide.classList.remove("active");
        }
      }
      document.querySelector(`div${id}`).classList.add("active");

      // slide interactivity
      const index = slides.findIndex((x) => x.hash === id);
      app.charts(index);
      app.mapMarkers(index);
      document.body.scrollTo(0, 0);

      // on every slide change, animate the goals
      app.animateGoals();
    }

    function toggleLinks(id) {
      // does this slide exist?
      if (!document.querySelector(id)) return false;

      // for (const navLink of document.querySelectorAll(
      //   ".o-navigation-bar ul li"
      // )) {
      //   navLink.classList.contains("active")
      //     ? navLink.classList.remove("active")
      //     : "";
      // }

      // set dropdown-tableofcontents inactive
      for (const li of document.querySelectorAll(
        ".dropdown-tableofcontents li"
      )) {
        li.classList.remove("active");
      }

      const index = slides.findIndex((x) => x.hash === id);

      for (const arrowLink of app.$arrowLinks) {
        arrowLink.style.display = "none";

        if (arrowLink.id == "previous") {
          setTimeout(() => {
            arrowLink.href =
              index > 0 ? slides[index - 1].hash : slides[0].hash;
          }, 200);
          if (index > 0) arrowLink.style.display = "block";
        }

        if (arrowLink.id == "next") {
          setTimeout(() => {
            arrowLink.href =
              index < slides.length - 1
                ? slides[index + 1].hash
                : slides[slides.length - 1].hash;
          }, 200);
          if (index < slides.length - 1) arrowLink.style.display = "block";
        }
      }

      // document.querySelector(`li[data-href="${id}"]`).classList.add("active");
      // set text of inhoudstafel to current slide
      document.getElementById("inhoudstafel").innerHTML =
        slides[index].title + " <i class='mdi mdi-chevron-up'></i>";
    }

    // this.resetScroll();
  },
  charts(slideIndex) {
    // destroy any existing instances
    for (const chart of app.activeCharts) {
      chart.destroy();
    }

    // get the currentslide
    const currentSlide = slides[slideIndex];

    // does this slide contain one or more charts?
    if (currentSlide["charts"] == undefined) return false;

    // iterate to show every chart
    for (const chartData of currentSlide.charts) {
      // create chart on specific scroll position
      const waypoint = new Waypoint({
        // element: document.getElementById(chartData.waypoint),
        element: document.querySelector(currentSlide.hash),
        handler: function (direction) {
          // get the canvas element
          const ctx = document.getElementById(chartData.target);
          // if chart is nog generated yet
          if (!ctx.classList.contains("chartjs-render-monitor")) {
            const newChart = new Chart(ctx, chartData.data);
            app.activeCharts.push(newChart);
          }
        },
        offset: 200, // a little bit earlier
      });
    }
  },
  parallaxEffects() {
    var image = document.getElementsByClassName("parallax");
    new simpleParallax(image, {
      scale: 1.3,
      delay: 0.6,
      transition: "cubic-bezier(0,0,0,1)",
    });
  },
  mapMarkers(slideIndex) {
    // get the currentslide
    const currentSlide = slides[slideIndex];
    if (currentSlide["map"] == undefined) return false;

    // check if this slide contains a map, and if this map is not already generated
    for (const map of app.activeMaps) {
      if (map === currentSlide.map) return false;
    }

    // add this map to active map array
    app.activeMaps.push(currentSlide.map);

    // show map on specific location
    showMap(currentSlide.map);
  },
};

app.init();

// <script async="true" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAMGfNFLfsvENHr4_hjR6ZSZBp6x56zaI0&amp;language=nl&amp;callback=initMap"></script>
export default function showMap(location) {
  if (location !== "locator-map") return false;

  fetch("./js/data/kantoren.json")
    .then((response) => response.json())
    .then((data) => {
      const kanDir =
        language === "nl" ? "Uw kantoordirecteur" : "Votre directeur d'agence";
      const meerInfo =
        language === "nl" ? "Meer informatie" : "Plus d'informations";
      const website =
        language === "nl"
          ? "https://www.europabank.be/nl/onze-kantoren"
          : "https://www.europabank.be/fr/nos-agences";
      fillMap(data, website, kanDir, meerInfo);
    });

  function fillMap(data, website, kanDir, meerInfo) {
    if (data.errorMessage) {
      return;
    }

    const map = new google.maps.Map(document.getElementById("locator-map"), {
      center: { lat: 50.805935, lng: 4.432983 },
      zoom: 11,
    });

    const n = [
      {
        stylers: [
          {
            hue: "#000",
          },
          {
            saturation: -100,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            lightness: 100,
          },
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
    ];

    map.setOptions({
      styles: n,
    });

    for (const kantoor of data) {
      const marker = new google.maps.Marker({
        position: { lat: kantoor.latitude, lng: kantoor.longitude },
        map: map,
        icon: "/images/marker.png",
      });

      // set marker icon size
      marker.setIcon({
        url: "/images/marker.png",
        scaledSize: new google.maps.Size(20, 30),
      });

      // disable satellite view
      map.setOptions({ mapTypeControl: false });

      // disable fullscreen
      map.setOptions({ fullscreenControl: false });

      // disable street view
      map.setOptions({ streetViewControl: false });

      // set zoom to top right
      map.setOptions({
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
      });

      const contentString = `
          <div class="locator_popup">
            <a target="_blank" href="${website}/${kantoor.url}" class="nodecoration">
              <div class="title">Europabank</div>
              <div class="subtitle">${kantoor.kantoorNaam}</div>
            </a>
            <div class="locator_content">
              <div class="content_left">${kantoor.kantoorAdres}<br>
                ${kantoor.kantoorPostNr} ${kantoor.kantoorStad}<br>
                Tel.: ${kantoor.kantoorTelNr}<br>
                Fax: ${kantoor.kantoorFaxNr}<br>
                <a href="mailto:${kantoor.kantoorEmail}">
                  ${kantoor.kantoorEmail}
                </a>
              </div>
              <div class="content_right">
                <strong>${kanDir}</strong><br>
                 ${kantoor.kantoorDirecteur}<br>
                <br>
                <a target="_blank" href="${website}/${kantoor.url}"><i class="mdi mdi-chevron-right"></i> ${meerInfo}</a>
              </div>
            </div>
          </div>`;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", () => {
        // close all other infowindows
        const infowindows = document.querySelectorAll(".gm-style-iw");
        infowindows.forEach((iw) => {
          iw.style.display = "none";
        });
        infowindow.open(map, marker);
      });
    }

    const bounds = new google.maps.LatLngBounds();

    for (const kantoor of data) {
      bounds.extend(
        new google.maps.LatLng(kantoor.latitude, kantoor.longitude)
      );
    }

    map.fitBounds(bounds);

    // disable panning outside of the bounds
    google.maps.event.addListener(map, "dragend", function () {
      if (bounds.contains(map.getCenter())) return;

      var c = map.getCenter(),
        x = c.lng(),
        y = c.lat(),
        maxX = bounds.getNorthEast().lng(),
        maxY = bounds.getNorthEast().lat(),
        minX = bounds.getSouthWest().lng(),
        minY = bounds.getSouthWest().lat();

      if (x < minX) x = minX;
      if (x > maxX) x = maxX;
      if (y < minY) y = minY;
      if (y > maxY) y = maxY;

      map.setCenter(new google.maps.LatLng(y, x));
    });
  }
}

import addSeparatorsNF from "../utils/helpers.js";

const slides_nl = [
  {
    title: "Jaarverslag 2023",
    slug: "cover",
    hash: "#cover",
  },
  {
    title: "Europabank en Crelan groep",
    slug: "groep-crelan",
    hash: "#groep-crelan",
  },
  {
    title: "Woord vooraf",
    slug: "voorwoord",
    hash: "#voorwoord",
  },
  {
    title: "Verslag boekjaar 2023",
    slug: "verslag-boekjaar-2023",
    hash: "#verslag-boekjaar-2023",
    charts: [
      {
        target: "winstevolutie",
        waypoint: "waypoint-winstevolutie",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "ROE",
                yAxisID: "roe",
                data: [14.1, 16.8, 17.8, 18.3, 19.2, 18],
                tension: 0,
                type: "line",
                borderColor: "#05581d",
                backgroundColor: "rgba(255,255,255,0)",
                borderWidth: 3,
              },
              {
                label: "winstevolutie (in duizend euro)",
                yAxisID: "winstevolutie",
                xAxisID: "xwinst",
                data: [20914.5, 24835, 27480.0, 28290.3, 31537, 30546.9],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            scales: {
              x: {
                stacked: true,
                display: false,
              },
              xwinst: {
                // make the labels bold
                stacked: true,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              winstevolutie: {
                type: "linear",
                position: "left",
                stacked: false,
                beginAtZero: true,
                min: 0,
                max: 35000,
                ticks: {
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                  font: {
                    weight: "bold",
                  },
                },
              },
              roe: {
                type: "linear",
                position: "right",
                stacked: false,
                min: 9,
                max: 23,
                ticks: {
                  stepSize: 2,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = "";
                    if (tooltipItem.datasetIndex == 1) {
                      const input = Number(tooltipItem.raw).toFixed(2);
                      label += "€ " + addSeparatorsNF(input, ".", ",", ".");
                    } else {
                      let input = tooltipItem.raw.toFixed(1);
                      label += addSeparatorsNF(input, ".", ",", ".") + " %";
                    }
                    return label;
                  },
                },
              },
              legend: {
                position: "bottom",
                align: "start",
              },
            },
          },
        },
      },
      {
        target: "balans",
        waypoint: "waypoint-balans",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Balans (in miljoen euro)",
                yAxis: "balans",
                data: [1417.5, 1537.4, 1643.2, 1791.5, 1856, 2075.7],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var input = Number(tooltipItem.raw).toFixed(2);
                    var num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              balans: {
                type: "linear",
                position: "left",
                min: 0,
                max: 2200,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 200,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
      {
        target: "vermogen",
        waypoint: "waypoint-balans",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "CET 1",
                yAxisID: "cet1",
                data: [17.3, 16.2, 15.7, 16.4, 15.1, 19],
                tension: 0,
                type: "line",
                borderColor: "#0f963e",
                backgroundColor: "rgba(255,255,255,0)",
                borderWidth: 3,
              },
              {
                label: "eigen vermogen (in miljoen euro)",
                yAxisID: "vermogen",
                data: [148.27, 154.44, 155.0, 163.89, 169.91, 226, 91],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            x: {
              ticks: {
                font: {
                  weight: "bold",
                },
              },
            },
            scales: {
              vermogen: {
                type: "linear",
                position: "left",
                min: 140,
                max: 240,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 20,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
              cet1: {
                type: "linear",
                position: "right",
                min: 15,
                max: 20,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 1,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = "";

                    if (tooltipItem.datasetIndex == 1) {
                      var input = Number(tooltipItem.raw).toFixed(2);
                      label += "€ " + addSeparatorsNF(input, ".", ",", ".");
                    } else {
                      const input = tooltipItem.formattedValue;
                      label += addSeparatorsNF(input, ".", ",", ".") + " %";
                    }

                    return label;
                  },
                },
              },
              legend: {
                position: "bottom",
                align: "start",
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "Onze kernactiviteiten",
    slug: "kernactiviteiten",
    hash: "#kernactiviteiten",
    charts: [
      {
        target: "deposito",
        waypoint: "waypoint-deposito",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Deposito",
                yAxisID: "deposito",
                data: [1253.9, 1359.9, 1474.4, 1614.5, 1672, 1834.2],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const input = Number(tooltipItem.raw).toFixed(2);
                    const num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },

              deposito: {
                type: "linear",
                position: "left",
                min: 0,
                max: 2000,
                ticks: {
                  stepSize: 200,
                  font: {
                    weight: "bold",
                  },
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
      {
        target: "kredietportefeuille",
        waypoint: "waypoint-kredietportefeuille",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Kredietportefeuille",
                yAxisID: "kredietportefeuille",
                data: [1051.8, 1161.8, 1188.0, 1265.4, 1404.6, 1575.2],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const input = Number(tooltipItem.raw).toFixed(2);
                    const num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              kredietportefeuille: {
                type: "linear",
                position: "left",
                min: 0,
                max: 1600,
                ticks: {
                  stepSize: 200,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "Klantentevredenheid",
    slug: "klantentevredenheid",
    hash: "#klantentevredenheid",
  },
  {
    title: "Brick-and-clickstrategie",
    slug: "brick-and-click",
    hash: "#brick-and-click",
  },
  {
    title: "ESG-beleid",
    slug: "esg-beleid",
    hash: "#esg-beleid",
  },
  {
    title: "Personeel",
    slug: "personeel",
    hash: "#personeel",
    charts: [
      {
        target: "personeel-chart",
        waypoint: "waypoint-personeel",
        data: {
          type: "line",
          data: {
            datasets: [
              {
                label: "Personeelsevolutie",
                yAxisID: "personeel",
                lineTension: 0,
                data: [338, 355, 355, 351, 361, 375],
                backgroundColor: "#fff786",
                borderColor: "#ffed06",
                fill: true,
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              personeel: {
                type: "linear",
                position: "left",
                min: 260,
                max: 380,
                ticks: {
                  stepSize: 20,
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "Connect, Organisatie en Informatica",
    slug: "connect-organisatie-en-informatica",
    hash: "#connect-organisatie-en-informatica",
  },
  {
    title: "Deugdelijk bestuur",
    slug: "deugdelijk-bestuur",
    hash: "#deugdelijk-bestuur",
  },
  {
    title: "Risico's en risicobeheer",
    slug: "risicobeheer",
    hash: "#risicobeheer",
  },
  {
    title: "Compliance",
    slug: "compliance",
    hash: "#compliance",
  },
  {
    title: "De jaarresultaten",
    slug: "jaarresultaten",
    hash: "#jaarresultaten",
  },
  {
    title: "Balans",
    slug: "balans",
    hash: "#balans",
  },
  {
    title: "Resultatenrekening",
    slug: "resultatenrekening",
    hash: "#resultatenrekening",
  },
  {
    title: "Kantoren",
    slug: "kantoren",
    hash: "#kantoren",
    map: "locator-map",
  },
];

const slides_fr = [
  {
    title: "Rapport annuel 2023",
    slug: "cover",
    hash: "#cover",
  },
  {
    title: "Europabank et le groupe Crelan",
    slug: "groupe-crelan",
    hash: "#groupe-crelan",
  },
  {
    title: "Avant-propos",
    slug: "avant-propos",
    hash: "#avant-propos",
  },
  {
    title: "Rapport du Conseil 2023",
    slug: "rapport-du-conseil-2023",
    hash: "#rapport-du-conseil-2023",
    charts: [
      {
        target: "winstevolutie",
        waypoint: "waypoint-winstevolutie",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "ROE en %",
                yAxisID: "roe",
                data: [14.1, 16.8, 17.8, 18.3, 19.2, 18],
                tension: 0,
                type: "line",
                borderColor: "#05581d",
                backgroundColor: "rgba(255,255,255,0)",
                borderWidth: 3,
              },
              {
                label: "bénéfice (en milliers d’euros)",
                yAxisID: "winstevolutie",
                xAxisID: "xwinst",
                data: [20914.5, 24835, 27480.0, 28290.3, 31537, 30546.9],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            scales: {
              x: {
                stacked: true,
                display: false,
              },
              xwinst: {
                // make the labels bold
                stacked: true,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              winstevolutie: {
                type: "linear",
                position: "left",
                stacked: false,
                beginAtZero: true,
                min: 0,
                max: 35000,
                ticks: {
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                  font: {
                    weight: "bold",
                  },
                },
              },
              roe: {
                type: "linear",
                position: "right",
                stacked: false,
                min: 9,
                max: 23,
                ticks: {
                  stepSize: 2,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = "";
                    if (tooltipItem.datasetIndex == 1) {
                      const input = Number(tooltipItem.raw).toFixed(2);
                      label += "€ " + addSeparatorsNF(input, ".", ",", ".");
                    } else {
                      let input = tooltipItem.raw.toFixed(1);
                      label += addSeparatorsNF(input, ".", ",", ".") + " %";
                    }
                    return label;
                  },
                },
              },
              legend: {
                position: "bottom",
                align: "start",
              },
            },
          },
        },
      },
      {
        target: "balans",
        waypoint: "waypoint-balans",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Évolution du bilan (en millions d'euros)",
                yAxis: "balans",
                data: [1417.5, 1537.4, 1643.2, 1791.5, 1856, 2075.7],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var input = Number(tooltipItem.raw).toFixed(2);
                    var num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              balans: {
                type: "linear",
                position: "left",
                min: 0,
                max: 2200,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 200,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
      {
        target: "vermogen",
        waypoint: "waypoint-balans",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "CET 1",
                yAxisID: "cet1",
                data: [17.3, 16.2, 15.7, 16.4, 15.1, 19],
                tension: 0,
                type: "line",
                borderColor: "#0f963e",
                backgroundColor: "rgba(255,255,255,0)",
                borderWidth: 3,
              },
              {
                label: "fonds propres (en millions d’euros)",
                yAxisID: "vermogen",
                data: [148.27, 154.44, 155.0, 163.89, 169.91, 226, 91],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            x: {
              ticks: {
                font: {
                  weight: "bold",
                },
              },
            },
            scales: {
              vermogen: {
                type: "linear",
                position: "left",
                min: 140,
                max: 240,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 20,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
              cet1: {
                type: "linear",
                position: "right",
                min: 15,
                max: 20,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                  stepSize: 1,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = "";

                    if (tooltipItem.datasetIndex == 1) {
                      var input = Number(tooltipItem.raw).toFixed(2);
                      label += "€ " + addSeparatorsNF(input, ".", ",", ".");
                    } else {
                      const input = tooltipItem.formattedValue;
                      label += addSeparatorsNF(input, ".", ",", ".") + " %";
                    }

                    return label;
                  },
                },
              },
              legend: {
                position: "bottom",
                align: "start",
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "Notre activités principales",
    slug: "activites-principales",
    hash: "#activites-principales",
    charts: [
      {
        target: "deposito",
        waypoint: "waypoint-deposito",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Deposito",
                yAxisID: "deposito",
                data: [1253.9, 1359.9, 1474.4, 1614.5, 1672, 1834.2],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const input = Number(tooltipItem.raw).toFixed(2);
                    const num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },

              deposito: {
                type: "linear",
                position: "left",
                min: 0,
                max: 2200,
                ticks: {
                  stepSize: 200,
                  font: {
                    weight: "bold",
                  },
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
      {
        target: "kredietportefeuille",
        waypoint: "waypoint-kredietportefeuille",
        data: {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Portefeuille des crédits ",
                yAxisID: "kredietportefeuille",
                data: [1051.8, 1161.8, 1188.0, 1265.4, 1404.6, 1575.2],
                backgroundColor: "#ffed06",
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const input = Number(tooltipItem.raw).toFixed(2);
                    const num = "€ " + addSeparatorsNF(input, ".", ",", ".");
                    return num;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              kredietportefeuille: {
                type: "linear",
                position: "left",
                min: 0,
                max: 1600,
                ticks: {
                  stepSize: 200,
                  callback: function (value, index, values) {
                    return "€ " + addSeparatorsNF(value, ".", ",", ".");
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "La satisfaction de la clientèle",
    slug: "satisfaction-clientele",
    hash: "#satisfaction-clientele",
  },
  {
    title: "Stratégie briques et clics",
    slug: "brick-and-click",
    hash: "#brick-and-click",
  },
  {
    title: "Politique ESG",
    slug: "politique-esg",
    hash: "#politique-esg",
  },
  {
    title: "Personnel",
    slug: "personnel",
    hash: "#personnel",
    charts: [
      {
        target: "personeel-chart",
        waypoint: "waypoint-personeel",
        data: {
          type: "line",
          data: {
            datasets: [
              {
                label: "Évolution des effectifs",
                yAxisID: "personeel",
                lineTension: 0,
                data: [338, 355, 355, 351, 361, 375],
                backgroundColor: "#fff786",
                borderColor: "#ffed06",
                fill: true,
              },
            ],
            labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              personeel: {
                type: "linear",
                position: "left",
                min: 260,
                max: 380,
                ticks: {
                  stepSize: 20,
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    title: "Connect, Organisation et Informatique",
    slug: "connect-organisation-et-informatique",
    hash: "#connect-organisation-et-informatique",
  },
  {
    title: "La gouvernance d’entreprise",
    slug: "gouvernance-entreprise",
    hash: "#gouvernance-entreprise",
  },
  {
    title: "Risques et maîtrise des risques",
    slug: "risques",
    hash: "#risques",
  },
  {
    title: "Compliance",
    slug: "compliance",
    hash: "#compliance",
  },
  {
    title: "Résultats annuels",
    slug: "resultats-annuels",
    hash: "#resultats-annuels",
  },
  {
    title: "Bilan",
    slug: "bilan",
    hash: "#bilan",
  },
  {
    title: "Compte de résultat",
    slug: "compte-de-resultat",
    hash: "#compte-de-resultat",
  },
  {
    title: "Agences",
    slug: "agences",
    hash: "#agences",
    map: "locator-map",
  },
];

const slides = language == "nl" ? slides_nl : slides_fr;

export default slides;
